<template>
  <div class="content">
    <div class="operate">
      <el-input
        v-model="searchGroupName"
        style="width: 150px; margin-right:10px"
        :placeholder="$t('config.groupNamePlaceholder')"
      />
      <el-button
        type="primary"
        @click="getGroupList"
      >
        {{ $t("common.find") }}
      </el-button>
      <el-button
        type="primary"
        @click="addGroup"
      >
        {{ $t("config.addGroupConfig") }}
      </el-button>
      <div class="uploadBox">
        <el-upload
          :headers="{Token:token}"
          :show-file-list="false"
          name="group_file"
          :action="`${url}/config_api/upload_group`"
          :on-success="successUpload"
          :file-list="fileList"
        >
          <el-button
            size="small"
            type="primary"
          >
            {{ $t("common.import") }}
          </el-button>
        </el-upload>
      </div>
    </div>
    <div>
      <el-table
        v-loading="tableLoading"
        :data="groupList"
        border
        :element-loading-text="$t('common.loading')"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column
          prop="group_name"
          label="Group"
          header-align="center"
          width="140"
        />
        <el-table-column
          prop="profile_count"
          :label="$t('config.profileAmount')"
          align="center"
          width="140"
        />
        <!-- <el-table-column prop="rtmp_count" :label="$t('config.rtmpAmount')" align="center"></el-table-column> -->
        <el-table-column
          prop="server_domain"
          :label="$t('config.domainOrIp')"
          align="center"
        />
        <el-table-column
          prop="version"
          :label="$t('config.version')"
          align="center"
        />
        <el-table-column
          prop="publish"
          :label="$t('config.isPublish')"
          width="120"
          align="center"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.publish"
              type="success"
            >
              {{ isPublish(scope.row.publish) }}
            </el-tag>
            <el-tag
              v-else
              type="danger"
            >
              {{ isPublish(scope.row.publish) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('common.operate')"
          width="450"
          header-align="center"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleDetail(scope.$index, scope.row)"
            >
              {{ $t("common.edit") }}
            </el-button>
            <el-button
              type="primary"
              size="mini"
              @click="release(scope.row)"
            >
              {{ $t("config.send") }}
            </el-button>
            <el-button
              type="primary"
              size="mini"
              @click="turnToRetweet(scope.row.group_name)"
            >
              {{ $t("config.retweet") }}
            </el-button>
            <el-button
              type="primary"
              size="mini"
              @click="exportGroup(scope.row.group_name)"
            >
              {{ $t("common.export") }}
            </el-button>
            <el-button
              v-if="!defaultGroup.includes(scope.row.group_name)"
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
            >
              {{ $t("common.delete") }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page">
      <el-pagination
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <!-- 选择下发服务器 -->
    <el-dialog
      :title="$t('editServer.chooseServer')"
      :visible.sync="serverDialog"
      width="400px"
    >
      <el-select
        v-model="chooseServer"
        :popper-append-to-body="false"
        clearable
        filterable
        multiple
      >
        <el-option
          v-for="item in serverList"
          :key="item.id"
          :value="item.id"
          :label="item.server_ip"
        />
      </el-select>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="serverDialog = false">{{ $t("common.cancel") }}</el-button>
        <el-button
          type="primary"
          @click="confirmRelease"
        >{{ $t("common.confirm") }}</el-button>
        <el-button
          type="primary"
          @click="ReleaseAll"
        >{{ $t("config.sendAll") }}</el-button>
      </span>
    </el-dialog>
    <!-- 发送结果展示 -->
    <el-dialog
      :title="$t('editServer.groupSenddetail')"
      :visible.sync="groupResultDialog"
    >
      <el-table
        :data="groupSendResult"
        border
      >
        <el-table-column
          prop="server_ip"
          :label="$t('editServer.serverIp')"
        />
        <el-table-column
          prop="server_type"
          :label="$t('editServer.serverType')"
        />
        <el-table-column
          prop="success"
          :label="$t('editServer.sendResult')"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.success"
              style="color:#67C23A"
            > {{ releaseFilter(scope.row.success) }} </span>
            <span
              v-else
              style="color: #F56C6C"
            > {{ releaseFilter(scope.row.success) }} </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="msg"
          :label="$t('editServer.sendDetail')"
        />
      </el-table>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="groupResultDialog = false"
        >{{ $t("common.confirm") }}</el-button>
      </span>
    </el-dialog>
    <!-- 删除结果展示 -->
    <el-dialog
      :title="$t('editServer.deleteDetail')"
      :visible.sync="groupDeleteDialog"
    >
      <el-table
        :data="deleteResult"
        border
      >
        <el-table-column
          prop="server_ip"
          :label="$t('editServer.serverIp')"
        />
        <el-table-column
          prop="server_type"
          :label="$t('editServer.serverType')"
        />
        <el-table-column
          prop="success"
          :label="$t('editServer.deleteDetail')"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.success"
              style="color:#67C23A"
            > {{ releaseFilter(scope.row.success) }} </span>
            <span
              v-else
              style="color: #F56C6C"
            > {{ releaseFilter(scope.row.success) }} </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="msg"
          :label="$t('common.remark')"
        />
      </el-table>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="groupDeleteDialog = false"
        >{{ $t("common.confirm") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  get_group_list,
  delete_group,
  send_group,
  get_server_list_select,
  set_all,
  not_del_groups,
  export_group
} from '@/api/config'
import { mapMutations, mapGetters } from 'vuex'
import { customConfig } from '@/api/request'
import { zh } from '@/api/code.js'
import { blobDodnload } from '@/common/util.js'
export default {
  data () {
    return {
      groupResultDialog: false,
      groupSendResult: [],
      serverDialog: false,
      chooseServer: [],
      serverList: [],
      chooseGroup: '',
      currentPage: 1,
      pageSize: 10,
      total: 0,
      groupList: [],
      fileList: [],
      url: customConfig.baseURL,
      defaultGroup: [],
      searchGroupName: '',
      tableLoading: false,
      groupDeleteDialog: false,
      deleteResult: null
    }
  },
  computed: {
    ...mapGetters(['token'])
  },
  created () {
    this.pageSize = Number(localStorage.groupPageSize) || 10
    this.init()
  },
  methods: {
    ...mapMutations(['updateCurrentGroup', 'addToken', 'addSAID']),
    // 是否已发布
    isPublish (param) {
      if (param) return this.$t('common.yes')
      return this.$t('common.no')
    },
    turnToRetweet (groupname) {
      this.$router.push(`/group/retweet/${groupname}`)
    },
    async exportGroup (group) {
      const res = await export_group({ group })
      blobDodnload(res)
    },
    addGroup () {
      this.$router.push('/group/add_group')
    },
    // 获取不能删除的group
    async getDefaultGroup () {
      const res = await not_del_groups()
      if (res.data.code === 0) {
        this.defaultGroup = res.data.data
      }
    },
    // 下发全部
    async ReleaseAll () {
      this.serverDialog = false
      const loading = this.$globalLoading(this.$t('editServer.sending'))
      const result = await set_all({ group_name: this.chooseGroup })
      if (result.data.code === 0) {
        this.groupSendResult = result.data.data
        this.groupResultDialog = true
        this.getGroupList()
      }
      loading.close()
    },
    // 过滤下发结果
    releaseFilter (value) {
      if (value) {
        return this.$t('common.succ')
      } else {
        return this.$t('common.fail')
      }
    },
    // 获取下发服务器列表
    async getServerListSelect () {
      const res = await get_server_list_select()
      if (res.data.code === 0) {
        console.log(res.data.data)
        this.serverList = res.data.data
      }
    },
    // 打开下发窗口
    release (row) {
      this.chooseGroup = row.group_name
      this.serverDialog = true
    },
    // 确认下发
    async confirmRelease () {
      this.serverDialog = false
      const loading = this.$globalLoading(this.$t('editServer.sending'))
      const params = {
        group_name: this.chooseGroup,
        server_id_list: this.chooseServer
      }
      const res = await send_group(params)
      if (res.data.code === 0) {
        this.groupSendResult = res.data.data
        this.groupResultDialog = true
        this.getGroupList()
      }
      loading.close()
    },
    // 导入
    successUpload (res) {
      if (res.code === 1002) {
        this.addToken('')
        this.addSAID('')
        this.$router.push('/login')
        return
      }
      if (localStorage.lang == 'en') {
        if (res.code === 0) {
          this.$message.success(this.$t('common.importSuc'))
          this.getGroupList()
        } else {
          this.$message.error(res.msg)
        }
      } else {
        if (res.code === 0) {
          this.$message.success(this.$t('common.importSuc'))
          this.getGroupList()
        } else {
          this.$message.error(zh[res.code])
        }
      }
    },
    // 编辑
    handleDetail (index, row) {
      this.updateCurrentGroup(row.group_name)
      this.$router.push('/group/group_detail')
    },
    // 删除
    handleDelete (index, row) {
      this.$confirm(this.$t('common.isConfirmDelete'), this.$t('common.remind'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {
        const loading = this.$globalLoading(this.$t('common.deleteing'))
        delete_group({ group_name: row.group_name })
          .then((res) => {
            if (res.data.code === 0) {
              this.groupDeleteDialog = true
              // this.$message.success(this.$t("common.deleteSuccess"));
              this.deleteResult = res.data.data
              this.getGroupList()
            }
            loading.close()
          })
      })
        .catch(() => {})
    },
    // 修改页码数据多少
    handleSizeChange (size) {
      this.pageSize = size
      localStorage.groupPageSize = size
      this.getGroupList()
    },
    // 当前页码
    handleCurrentChange (current_page) {
      this.currentPage = current_page
      this.getGroupList()
    },
    // 获取Group列表
    async getGroupList () {
      this.tableLoading = true
      const params = {
        group_name: this.searchGroupName,
        page_index: this.currentPage,
        page_size: this.pageSize
      }
      const res = await get_group_list(params)
      if (res.data.code === 0) {
        this.groupList = res.data.data.group_list
        this.total = res.data.data.group_count
      }
      this.tableLoading = false
    },
    init () {
      this.getGroupList()
      this.getDefaultGroup()
      this.getServerListSelect()
    }
  }
}
</script>

<style lang='scss' scoped>
.content {
  padding-right: 20px;
  margin-top: 20px;
}
.operate {
  display: flex;
  padding-bottom: 10px;
}
.page {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
}
.uploadBox {
  margin-left: 10px;
  height: 100%;
  display: inline-block;
}
.upload {
  height: 100%;
  display: flex;
}
</style>
